import React from 'react';
import logo from './logo_yoga.png';
import banner from './yoga-abracoliberta.jpg';
import logo_mcm from './logo-minhacintamodeladora.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <img className="banner" src={banner} alt="Logo Cintas Modeladoras Yoga" />
      <header className="App-header">
        <p>
            Conheça nossa nova loja online:<br /><br />
            <a href="https://www.minhacintamodeladora.com.br?utm_media=centercintas"><img src={logo_mcm} width="200px" alt="Logo Minha Cinta Modeladora"/></a>
        </p>
        <p>
            <b>Já estamos atendendo na loja física!</b><br />
        </p>
      </header>
      <div >
        <p>
          Qualquer dúvida, entre em contato conosco pelo email abaixo:<br />
          contato@yogamodeladores.com.br<br />
        </p>

        <p><br />
          <img src={logo} width="200px" alt="Logo Cintas Modeladoras Yoga" /><br /><br />
          Yoga Modeladores Vila Clementino<br />
          Rua Doutor Altino Arantes, 137 - São Paulo - Próx. ao Metrô Santa Cruz<br />
          11 5587-4555  |  vc@yogamodeladores.com.br<br />
          <a href="https://yogamodeladores.com.br">yogamodeladores.com.br</a>
        </p>
      </div>
    </div>
  );
}

export default App;
